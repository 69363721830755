import { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

const usePrivacyAnimationMobile = () => {
  const ANIMATION_TYPE = "ease-in-out";
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    let timerId = setTimeout(() => {
      const rootContainerElement = document.getElementById("root_container");
      const lockMobileElement = document.getElementById("lockMobile_ref");
      const lockVerMobileElement = document.getElementById("lockVerMobile_ref");
      const lockPrivacyMobileElement = document.getElementById(
        "lockPrivacyMobile_ref"
      );

      gsap.to(lockMobileElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: lockPrivacyMobileElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        x: 0,
        y: "15",
        duration: 0.4,
        ease: ANIMATION_TYPE,
      });
      gsap.to(lockVerMobileElement, {
        scrollTrigger: {
          scroller: rootContainerElement,
          trigger: lockPrivacyMobileElement,
          start: "top center",
          toggleActions: "restart none none reverse",
        },
        opacity: 1,
        duration: 0.4,
        ease: ANIMATION_TYPE,
      });
    }, 300);
    return () => clearTimeout(timerId);
  }, []);
};
export default usePrivacyAnimationMobile;
