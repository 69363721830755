import usePrivacyAnimationMobile from "../../hooks/usePrivacyAnimationMobile";
import { LandingWhiteSec } from "../../shared-styled-components";
import { Image } from "../Image";
import SectionDividers from "../SectionDividers/SectionDividers";
import * as S from "./LandingPrivacyMobile.style";

const LandingPrivacyMobile = ({ title, list, imgs }: any) => {
  usePrivacyAnimationMobile();
  return (
    <>
      <LandingWhiteSec id="privacyMobile_ref">
        <S.LandingPrivacy id="lockPrivacyMobile_ref">
          <S.LandingPrivacyImg>
            <Image
              id="lockFrontMobile_ref"
              className="lockFront"
              {...imgs?.[0]}
              loading={imgs?.[0]?.loading || "lazy"}
            />
            <Image
              id="lockFront2Mobile_ref"
              className="lockFront2"
              {...imgs?.[1]}
              loading={imgs?.[1]?.loading || "lazy"}
            />
            <Image
              id="lockVerMobile_ref"
              className="lockVer"
              {...imgs?.[2]}
              loading={imgs?.[2]?.loading || "lazy"}
            />
            <Image
              id="lockMobile_ref"
              className="lockUpper"
              {...imgs?.[3]}
              loading={imgs?.[3]?.loading || "lazy"}
            />
          </S.LandingPrivacyImg>
          <div>
            <S.LandingPrivacyTitle>{title}</S.LandingPrivacyTitle>
            <S.LandingPrivacyTitleList>
              {list?.map((item: any, index: number) => {
                return <li key={index}>{item}</li>;
              })}
            </S.LandingPrivacyTitleList>
          </div>
        </S.LandingPrivacy>
      </LandingWhiteSec>
      <SectionDividers small />
    </>
  );
};

export default LandingPrivacyMobile;
