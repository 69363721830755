import styled from "styled-components";

export const LandingPrivacy = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 3rem;
`;

export const LandingPrivacyTitle = styled.h3`
  font-family: "Raleway";
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;
  color: #000000;
`;

export const LandingPrivacyImg = styled.div`
  background: linear-gradient(152.97deg, #ffffff 13.39%, #c7c7c7 87.18%);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  width: 104px;
  height: 104px;
  margin-bottom: 32px;
  padding-top: 20px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      152.97deg,
      rgba(236, 213, 213, 0.6) 13.39%,
      rgba(220, 220, 220, 0) 87.18%
    );
    border-radius: 100%;
    width: 104px;
    height: 104px;
    z-index: -1;
  }

  & .lockFront2 {
    position: absolute;
    top: 44px;
    left: 36px;
  }

  & .lockVer {
    position: absolute;
    opacity: 0;
    top: 48px;
    left: 39px;
  }

  & .lockUpper {
    position: absolute;
    z-index: -1;
    top: 5px;
    left: 39px;
  }
`;

export const LandingPrivacyTitleList = styled.ul`
  font-weight: 400;
  padding-left: 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
  line-height: 20px;

  & li {
    position: relative;
  }

  & li:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 14px;
    left: -18px;
    top: 2px;
    background: #ff4d00;
    border-radius: 14px;
  }
`;
